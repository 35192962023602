/* start vars: m-marketdata-info */
/* line 13, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.m-marketdata-info {
	margin-bottom: 20px;
}

/* line 17, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.marketdata-info__table-head {
	background: rgb(var(--color-global-widget));
	color: rgb(var(--color-global-primary));
	font-weight: 700;
	text-transform: uppercase;
}

/* line 23, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.marketdata-info__table-head .sortable {
	cursor: pointer;
}

/* line 27, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.marketdata-info__table-head .headercolumn:hover {
	color: #709fb3;
}

/* line 31, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.marketdata-info__table-head .grid__item {
	border-right: 1px solid rgb(var(--color-global-positive));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .marketdata-info__table-head .grid__item {
	border-right: 0;
	border-left: 1px solid rgb(var(--color-global-positive));
}

/* line 34, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.marketdata-info__table-head .grid__item.address {
	padding-left: 29px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .marketdata-info__table-head .grid__item.address {
	padding-left: initial;
	padding-right: 29px;
}

/* line 39, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.marketdata-info__table-head .icon {
	margin-left: 5px;
	font-size: 0.7em;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .marketdata-info__table-head .icon {
	margin-left: initial;
	margin-right: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .marketdata-info__table-head .icon {
		margin-left: 0;
	}
}

/* line 45, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.marketdata-info__table-row {
	color: rgb(var(--color-global-negative));
}

/* line 48, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.marketdata-info__table-row:nth-of-type(2n) {
	background-color: rgb(var(--color-global-widget));
}

/* line 52, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.marketdata-info__table-row:hover, .marketdata-info__table-row.selected {
	background-color: rgb(var(--color-global-secondary));
	color: rgb(var(--color-global-positive));
	cursor: pointer;
}

/* line 59, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.marketdata-info__table-row .grid__item {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

/* line 65, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.marketdata-info__table-row .icon {
	margin-right: 10px;
	display: inline-block;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .marketdata-info__table-row .icon {
	margin-right: initial;
	margin-left: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .marketdata-info__table-row .icon {
		margin-right: 0;
	}
}

/* line 71, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.no-multiple-listings-gutter {
	margin-left: 24px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .no-multiple-listings-gutter {
	margin-left: initial;
	margin-right: 24px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .no-multiple-listings-gutter {
		margin-left: 0;
	}
}

/* line 78, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.marketdata-info__table-head .grid__item,
.marketdata-info__table-row .grid__item {
	padding: 10px 5px;
	text-align: center;
}

/* line 82, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.marketdata-info__table-head .grid__item.address, .marketdata-info__table-head .grid__item.location,
.marketdata-info__table-row .grid__item.address,
.marketdata-info__table-row .grid__item.location {
	text-align: left;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .marketdata-info__table-head .grid__item.address, .is-rtl .marketdata-info__table-head .grid__item.location, .is-rtl
.marketdata-info__table-row .grid__item.address, .is-rtl
.marketdata-info__table-row .grid__item.location {
	text-align: right;
}

/* line 89, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.marketdata-details__title {
	margin-bottom: 10px;
	text-transform: capitalize;
}

/* line 93, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.marketdata-details__title--colored {
	color: rgb(var(--color-global-text));
}

/* line 100, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.market-analysis__form .error {
	text-align: left;
	display: block;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .market-analysis__form .error {
	text-align: right;
}

/* line 106, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.market-analysis__form-modal {
	display: none;
}

/* line 110, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.market-analysis__form-button {
	margin: 10px 20px 0 20px;
}

/* line 115, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.ib-marketdata-close {
	right: 10px;
	position: absolute;
	top: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .ib-marketdata-close {
	right: auto;
	left: 10px;
}

/* line 121, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.ib-marketdata {
	margin-left: 0;
	position: relative;
	margin-top: 5px;
	max-width: calc(100% - 290px);
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .ib-marketdata {
	margin-left: initial;
	margin-right: 0;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .ib-marketdata {
		margin-left: 0;
	}
}

@media only screen and (max-width: 59.999em) {
	/* line 121, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
	.ib-marketdata {
		max-width: 100%;
		margin-left: 20px;
		margin-right: 20px;
	}
}

/* line 134, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.marketdata-info__table .price__value {
	color: inherit;
	font-size: inherit;
	font-family: inherit;
	font-display: swap;
}

/* line 141, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.marketdata-results__title {
	text-transform: capitalize;
}

/* line 145, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.marketdata-results__form .grid--spaced-all .suggest__container {
	margin-left: 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .marketdata-results__form .grid--spaced-all .suggest__container {
	margin-left: initial;
	margin-right: 20px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .marketdata-results__form .grid--spaced-all .suggest__container {
		margin-left: 0;
	}
}

/* line 149, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.marketdata-results__search-wrapper {
	padding: 10px;
	background-color: rgb(var(--color-global-widget2));
	position: relative;
}

/* line 155, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.marketdata-results__search-input {
	padding-left: 30px;
	font-family: var(--font-family-headings);
	font-display: swap;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .marketdata-results__search-input {
	padding-left: initial;
	padding-right: 30px;
}

/* line 161, scss/80-themes/Saunders/70-modules/marketdatadetails/module.scss */
.marketdata-results__search-icon {
	transform: translateY(-50%);
	left: 20px;
	position: absolute;
	top: 50%;
	pointer-events: none;
	font-size: 1.1em;
	color: rgb(var(--color-global-secondary));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .marketdata-results__search-icon {
	left: auto;
	right: 20px;
}

/*# sourceMappingURL=../../../../../true */